.sys-message { user-select: none; cursor: pointer; position:absolute; top: var(--margin-md); left: 50%; transform: translate(-50%, 0); z-index: 20; padding: var(--padding-md); border-radius: var(--border-radius-md); box-shadow: var(--shadow-lg) var(--shadow-dark); text-align: center; opacity: 1; transition: opacity var(--animation-speed-fast) ease-out; width: 90%; max-width: 400px; }
.sys-message.close { opacity: 0; transition: opacity var(--animation-speed-fast) ease-out; }
.sys-message > h3 { margin: 0; }
.sys-message > p { margin: var(--margin-md); }
.sys-message > span { font-size: var(--font-size-sm); }
.sys-message.error { background: var(--background-error); }
.sys-message.warn { background: var(--background-warn); color: var(--font-color-dark); }
.sys-message.success { background: var(--background-success); }

.sys-message.notification { background: var(--background-notifications); bottom: 8rem; top: auto; left: 50%; max-width: fit-content; cursor: default; padding: var(--padding-xs); color: var(--font-color-light); }
