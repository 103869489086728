:root { /* XX-Large devices (larger desktops) *//* No media query since the breakpoint has no upper bound on its width */

	/* These are jst used as a reference to easily find/replace all media queries if theres a change in size */
	--breakpoint-tablet-max-width: 820px; /* This should match the values in AppConstants */
	--breakpoint-mobile-max-width: 480px; /* This should match the values in AppConstants */

	/* Font Sizes */
	--font-size-xs: 0.6rem;
	--font-size-sm: 0.8rem;
	--font-size-md: 1rem;
	--font-size-lg: 1.2rem;
	--font-size-xl: 1.5rem;
	--font-size-xxl: 2em;

	/* Padding Sizes */
	--padding-xxs: 0.1rem;
	--padding-xs: 0.3rem;
	--padding-sm: 0.6rem;
	--padding-md: 0.8rem;
	--padding-lg: 1rem;
	--padding-xl: 1.4rem;
	--padding-xxl: 1.8rem;

	/* Margin Sizes */
	--margin-xxs: 0.3rem;
	--margin-xs: 0.6rem;
	--margin-sm: 0.8rem;
	--margin-md: 1rem;
	--margin-lg: 2rem;
	--margin-xl: 3rem;

	/* Border Radius */
	--border-radius-sm: 4px;
	--border-radius-md: 8px;
	--border-radius-lg: 12px;
	--border-radius-50: 50%;

	/* Box Shadows */
		/* Shadows */
		--shadow-md: 0 0 4px 0;
		--shadow-lg: 0 0 10px 0;
		--shadow-rd-md: 5px 5px 6px -2px;
		--shadow-d-md: 0px 5px 6px -2px;

		/* Colours */
		--shadow-dark: black;
		--shadow-light: white;

	/* Other Shadows */
		--drop-shadow: 0 0 2px;

	/* Animation Speeds */
	--animation-speed-fastest: .1s;
	--animation-speed-fast: .25s;
	--animation-speed-normal: .5s;
	--animation-speed-slow: .75s;
	--animation-speed-slowest: 1s;

	/* Other - Some make more sense to use here - Even though they are very much component based */
	--header-height: 75px;
	--breadcrumbs-height: 50px;
	--loadingGrow-scale: 1;
	--glassmorph-backdrop-filter-blur: 4px;

	--accent-colour: #D62929; /* TODO: Move/Use this for scrollbar theming and other accent places (eg. gallery thumbnail)*/
}

@media (max-width: 820px /* var(--breakpoint-tablet-max-width) */) {
	
}
@media (max-width: 480px /* var(--breakpoint-mobile-max-width) */) {
	
}
