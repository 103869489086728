@import "./dark";
@import "./light";

#site-wrapper.dark {
	--background-site: var(--dark-background-site);
	--background-primary: var(--dark-background-primary);
	--background-secondary: var(--dark-background-secondary);
	--background-success: var(--dark-background-success);
	--background-warn: var(--dark-background-warn);
	--background-error: var(--dark-background-error);
	--background-loading: var(--dark-background-loading);
	--background-modal-overlay: var(--dark-background-modal-overlay);
	--background-glassmorph: var(--dark-background-glassmorph);
	--background-field-changed: var(--dark-background-field-changed);
	--background-disabled: var(--dark-background-disabled);
	--background-notifications: var(--dark-background-notifications);
	--background-inputs-primary: var(--dark-background-inputs-primary);
	--background-inputs-secondary: var(--dark-background-inputs-secondary);
	--background-success-dark: var(--dark-background-success-dark);

	--font-color-primary: var(--dark-font-color-primary);
	--font-color-secondary: var(--dark-font-color-secondary);
	--font-color-success: var(--dark-font-color-success);
	--font-color-warn: var(--dark-font-color-warn);
	--font-color-error: var(--dark-font-color-error);
	--font-color-dark: var(--dark-font-color-dark);
	--font-color-light: var(--dark-font-color-light);
	--font-color-info: var(--dark-font-color-info);
	--font-color-disabled: var(--dark-font-color-disabled);
	--font-color-eorzea: var(--dark-font-color-eorzea);

	--gradient-radial-primary: var(--dark-gradient-radial-primary);
	--gradient-radial-secondary: var(--dark-gradient-radial-secondary);

	--border-divider-color: var(--dark-border-divider-color);

	--link-color-primary: var(--dark-link-color-primary);

	--table-header-background-primary: var(--dark-table-header-background-primary);
	--table-header-background-secondary: var(--dark-table-header-background-secondary);
	--table-body-background-primary: var(--dark-table-body-background-primary);
	--table-body-background-secondary: var(--dark-table-body-background-secondary);
	--table-border-divider-color: var(--dark-table-border-divider-color);

	--tip-icon-colour: var(--dark-tip-icon-colour);
	--progress-stats-gradient-colour-1: var(--dark-progress-stats-gradient-colour-1);
	--progress-stats-gradient-colour-2: var(--dark-progress-stats-gradient-colour-2);
	--progress-stats-gradient-colour-3: var(--dark-progress-stats-gradient-colour-3);
	--progress-stats-gradient-colour-4: var(--dark-progress-stats-gradient-colour-4);
}

#site-wrapper.light {
	--background-site: var(--light-background-site);
	--background-primary: var(--light-background-primary);
	--background-secondary: var(--light-background-secondary);
	--background-success: var(--light-background-success);
	--background-warn: var(--light-background-warn);
	--background-error: var(--light-background-error);
	--background-loading: var(--light-background-loading);
	--background-modal-overlay: var(--light-background-modal-overlay);
	--background-glassmorph: var(--light-background-glassmorph);
	--background-field-changed: var(--light-background-field-changed);
	--background-disabled: var(--light-background-disabled);
	--background-notifications: var(--light-background-notifications);
	--background-inputs-primary: var(--light-background-inputs-primary);
	--background-inputs-secondary: var(--light-background-inputs-secondary);
	--background-success-dark: var(--light-background-success-dark);

	--font-color-primary: var(--light-font-color-primary);
	--font-color-secondary: var(--light-font-color-secondary);
	--font-color-success: var(--light-font-color-success);
	--font-color-warn: var(--light-font-color-warn);
	--font-color-error: var(--light-font-color-error);
	--font-color-dark: var(--light-font-color-dark);
	--font-color-light: var(--light-font-color-light);
	--font-color-info: var(--light-font-color-info);
	--font-color-disabled: var(--light-font-color-disabled);
	--font-color-eorzea: var(--light-font-color-eorzea);

	--gradient-radial-primary: var(--light-gradient-radial-primary);
	--gradient-radial-secondary: var(--light-gradient-radial-secondary);

	--border-divider-color: var(--light-border-divider-color);

	--link-color-primary: var(--light-link-color-primary);

	--table-header-background-primary: var(--light-table-header-background-primary);
	--table-header-background-secondary: var(--light-table-header-background-secondary);
	--table-body-background-primary: var(--light-table-body-background-primary);
	--table-body-background-secondary: var(--light-table-body-background-secondary);
	--table-border-divider-color: var(--light-table-border-divider-color);

	--tip-icon-colour: var(--light-tip-icon-colour);
	--progress-stats-gradient-colour-1: var(--light-progress-stats-gradient-colour-1);
	--progress-stats-gradient-colour-2: var(--light-progress-stats-gradient-colour-2);
	--progress-stats-gradient-colour-3: var(--light-progress-stats-gradient-colour-3);
	--progress-stats-gradient-colour-4: var(--light-progress-stats-gradient-colour-4);

}
