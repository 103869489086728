#site-header { background: var(--background-secondary); padding: 0 var(--padding-xl); display: flex; justify-content: space-between; align-items: center; height: var(--header-height); box-shadow: var(--shadow-d-md) var(--shadow-dark); z-index: 2; }
#site-header > div { display: flex; align-items: center; }

#site-header .menu-toggler { padding: 0 var(--padding-md) 0 0; font-size: var(--font-size-xl); cursor: pointer; border-right: 1px solid var(--border-divider-color); margin-right: var(--margin-sm); }
#site-header .menu-toggler:hover > svg { transform: scale(1.3); }

#site-header > div.right > div { display: inline-block; }

@media (max-width: 820px /* var(--breakpoint-tablet-max-width) */) {

}

@media (max-width: 480px /* var(--breakpoint-mobile-max-width) */) {
	#site-header .title > h1 { font-size: var(--font-size-xl); }
}
