@keyframes loadingGrow {
	0% { transform: scale(0); opacity: 0; }
	100% { transform: scale(var(--loadingGrow-scale)); opacity: 1; }
}

@keyframes hideLoadingOverlay {
	0% { opacity: 1; z-index: 10; }
	99% { z-index: 10; }
	100% { opacity: 0; z-index: -1; }
}

@keyframes showLoadingOverlay {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes themeChangeFadeOut {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

@keyframes themeChangeFadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes hideModal {
	0% { opacity: 1; z-index: 5; }
	99% { z-index: 5; }
	100% { opacity: 0; z-index: -1; }
}

@keyframes showModal {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes rotate {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(359deg); }
}
