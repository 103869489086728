#home .unknown-banner { background: var(--background-error); padding: var(--padding-xs); border-radius: var(--border-radius-md); text-align: center; margin: var(--margin-sm); }
#home .welcome-message { text-align: center; }
#home .welcome-message p { font-size: var(--font-size-md); margin: var(--margin-sm); }
#home .recent-changes { width: 50%; margin: var(--margin-lg) auto; }
#home .recent-changes h2, #home .recent-changes h3 { text-align: center; }
#home .recent-changes li { padding: var(--padding-xs); }
#home .changes-btn { margin: 0 auto; display: block; }


@media (max-width: 820px /* var(--breakpoint-tablet-max-width) */) {
	#home .recent-changes { width: 100%; }
}

@media (max-width: 480px /* var(--breakpoint-mobile-max-width) */) {

}
