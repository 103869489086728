#important-npcs .npc-wrapper { display: flex; gap: 1em; flex-wrap: wrap; }
#important-npcs .npc-wrapper .npc { flex-basis: calc(25% - 1em); background: var(--background-secondary); backdrop-filter: blur(var(--glassmorph-backdrop-filter-blur)); padding: var(--padding-sm); border-radius: var(--border-radius-md); box-shadow: var(--shadow-md) var(--shadow-dark); }
#important-npcs .npc-wrapper p { margin: var(--margin-xs) 0; }
#important-npcs .npc-wrapper img { margin-right: var(--margin-xxs); }
#important-npcs .npc-wrapper .no-npcs { margin: 0 auto; }

@media (max-width: 820px /* var(--breakpoint-tablet-max-width) */) {
	#important-npcs > h2 { text-align: center; }
	#important-npcs > .npc-wrapper { justify-content: center; }
}

@media (max-width: 480px /* var(--breakpoint-mobile-max-width) */) {
	#important-npcs .npc-wrapper .npc { flex-basis: calc(50% - 1em);; }
}
