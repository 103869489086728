.character-display.primary { background: var(--gradient-radial-primary); }
.character-display.primary.selected { background: var(--gradient-radial-secondary); }
.character-display.secondary { background: var(--gradient-radial-secondary); }
.character-display.secondary.selected {  background: var(--gradient-radial-primary); }

.character-display { text-align: left; padding: 0 var(--padding-md); border-radius: var(--border-radius-md); box-shadow: var(--shadow-md) var(--shadow-dark); margin: var(--margin-xs); display: flex; justify-content: left; align-items: center; }
.character-display .avatar-wrapper { border-left: 1px solid var(--border-divider-color); margin-left: var(--margin-sm); }
.character-display img { width: 64px; margin: 0 var(--margin-sm); border-radius: var(--border-radius-md); }
.character-display .additional-detail { color: var(--font-color-secondary); font-size: var(--font-size-sm); user-select: auto; cursor: default; }

.character-display .actions { display: flex; flex-direction: column; align-items: center;}
.character-display .actions > div:not(:last-child) { border-bottom: 1px solid var(--border-divider-color); }
.character-display .actions > div { padding: var(--padding-xs) 0; }

.character-display.minimalist { padding: var(--padding-xs) var(--padding-md); }
.character-display.minimalist img { width: 32px; }

.character-display.minimalist .name-wrapper { overflow: hidden; white-space: nowrap; }
.character-display.minimalist .name-wrapper > p { overflow: hidden; text-overflow: ellipsis; }

.character-display.selectable p { user-select: none; }
.character-display.selectable .actions svg { color: var(--font-color-disabled); }
.character-display.selectable:hover { transform: scale(var(--button-hover-scale)); cursor: pointer; }
.character-display.selectable.selected .actions svg { transform: scale(var(--icon-btn-hover-scale)); }
.character-display.selectable.selected .actions svg { color: var(--font-color-success); filter: drop-shadow(var(--drop-shadow) var(--font-color-primary)); }

@media (max-width: 820px /* var(--breakpoint-tablet-max-width) */) {
	
}
@media (max-width: 480px /* var(--breakpoint-mobile-max-width) */) {
	
}
