#menu { --menu-width: 300px; --menu-background: var(--background-secondary); --menu-animation-speed: var(--animation-speed-fast); }

#menu { width: var(--menu-width); min-width: var(--menu-width); overflow: auto; padding: var(--menu-padding); background: var(--menu-background); display: flex; flex-direction: column; transition: margin var(--menu-animation-speed) ease-out; box-shadow: var(--shadow-rd-md) var(--shadow-dark); z-index: 1; }
#menu > div.menu-wrapper { padding-bottom: var(--padding-md); flex-grow: 1; }
#menu.hide { margin-left: calc(-1 * var(--menu-width)); transition: margin var(--menu-animation-speed) ease-out; }

#menu .menu-header { border-bottom: 1px solid var(--border-divider-color); margin-bottom: var(--margin-sm); padding: var(--padding-md); }

#menu .menu-footer { border-top: 1px solid var(--border-divider-color); padding: var(--padding-xs) var(--padding-xl); display: flex; flex-direction: column; align-items: center; }
#menu .menu-footer > div { margin: var(--margin-xs) 0; }
#menu .menu-footer .patreon-wrapper iframe { max-width: 100%; width: 175px; }

#menu .menu-wrapper { padding: var(--padding-sm); }
#menu .menu-item { --hover-scale: 1.2; --chevron-rotation: 90deg; }
#menu .menu-item { border-radius: var(--border-radius-md); margin: var(--margin-xxs); padding: 0 var(--padding-sm); overflow: hidden; }
#menu .menu-item.bg-primary { background: var(--background-primary); }
#menu .menu-item.bg-secondary { background: var(--background-secondary); }
#menu .menu-item.open { padding: 0 var(--padding-sm) var(--padding-sm); }
#menu .menu-item > h3 { padding: var(--padding-md); margin: 0; cursor: pointer; display: flex; justify-content: space-between; }
#menu .menu-item.selected > h3 { cursor: default; }
#menu .menu-item > h3 > span { color: var(--font-color-secondary); }
#menu .menu-item > h3:hover > span { color: var(--font-color-primary); }
#menu .menu-item.selected > h3 > span { color: var(--font-color-primary); }
#menu .menu-item > h3 > svg { transition: transform var(--animation-speed-fastest) ease-out; }
#menu .menu-item.open > h3 > svg { transform: rotate(var(--chevron-rotation)); transition: transform var(--animation-speed-fastest) ease-out; }
#menu .menu-item > h3:hover > svg { transform: scale(var(--hover-scale)); color: var(--font-color-primary); }
#menu .menu-item.open > h3:hover > svg { transform: scale(var(--hover-scale)) rotate(var(--chevron-rotation)); transition: transform var(--animation-speed-fastest) ease-out; }

#menu .sub-menu { max-height: 0; overflow: hidden; }
#menu .sub-menu.open { max-height: max-content; }

#menu .menu-item.complete { background: var(--background-success-dark); }

@media (max-width: 820px /* var(--breakpoint-tablet-max-width) */) {
	#menu { --menu-width: 50%; }
}

@media (max-width: 480px /* var(--breakpoint-mobile-max-width) */) {
	#menu { --menu-width: 100%; }
}
