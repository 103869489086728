:root {
	--button-hover-scale: 1.05;
	--icon-btn-hover-scale: 1.2;
}

/* #region Form */ /* Determines display direction and spacing of fields */
.rt-form { display: flex; flex-wrap: wrap; }
.rt-form.row { flex-direction: row; }
.rt-form.column { flex-direction: column; }
.rt-form.around { justify-content: space-around; }
.rt-form.between { justify-content: space-between; }
.rt-form.evenly { justify-content: space-evenly; }
.rt-form.center { justify-content: center; }
/* #endregion Form */

/* #region Field Wrapper */
.field { margin: var(--margin-xxs); display: flex; }
/* #endregion Field Wrapper */

/* #region Label */
.field.label-p-top { flex-direction: column; }
.field.label-p-top .field-label { padding-bottom: var(--padding-xs); }

.field.label-a-left { align-items: start; }
.field.label-a-center { align-items: center; }
.field.label-a-right { align-items: end; }

.field .field-label { display: inline-block; text-align: right; margin-right: var(--margin-xs); } /* .field .field-label {  } */
/* #endregion Label */

/* #region Input Wrapper */
.field .input-wrapper { flex-grow: 1; display: flex; }
.field .input-wrapper.center { justify-content: center; }
/* #endregion Input Wrapper */

/* #region Inputs */ /* (Select boxes do not have .field-input class) */
.field .field-input, [class$="-control"] { background: var(--background-inputs-primary); border: none; border-radius: var(--border-radius-md); padding: var(--padding-sm); width: 100%; text-overflow: ellipsis; font: inherit; }
.field .field-input.primary { background: var(--background-inputs-primary); }
.field .field-input.secondary { background: var(--background-inputs-secondary); }
.field .field-input:disabled { cursor: default; opacity: 1; background: var(--background-disabled); }
.field .field-input:disabled::placeholder { color: var(--font-color-disabled); }
.field .field-free-text { margin: var(--margin-xs) 0; }

/* .field.minimalist { margin: var(--margin-xxs) 0; } */
/* .field.minimalist .field-input { padding: var(--padding-xxs) var(--padding-xs); border-radius: var(--border-radius-sm); } */

.field .field-input.hasChanged { background: var(--background-field-changed); }
/* #endregion Inputs */

/* #region Edit Button */
.field .field-edit { display: inline-block; padding: var(--padding-sm); cursor: pointer; }
.field .field-edit:hover { transform: scale(1.1); }
/* #endregion Edit Button */

/* #region Buttons & Links */
.rt-button { display: inline-block; background: var(--background-secondary); color: var(--font-color-primary); margin: var(--margin-sm); padding: var(--padding-sm) var(--padding-md); border: none; box-shadow: var(--shadow-md) var(--shadow-dark); border-radius: var(--border-radius-md); cursor: pointer; }
.rt-button:hover, a:hover { transform: scale(var(--button-hover-scale)); }
.rt-button .rt-icon, a .rt-icon { margin-right: var(--margin-xxs); }
.rt-button > span { vertical-align: middle; }
.rt-button.primary { background: var(--background-secondary) }
.rt-button.secondary { background: var(--background-primary) }
.rt-button.success { background: var(--background-success) }
.rt-button.warn { background: var(--background-warn) }
.rt-button.error { background: var(--background-error) }
.rt-button:disabled { opacity: 0.6; transform: none; cursor: default; box-shadow: none; }
.rt-button.link, a { display: inline-block; background: none; color: var(--link-color-primary); margin: 0; padding: 0; text-decoration: underline; text-underline-offset: 4px; box-shadow: none; }
.rt-button.plain, a.anchor { text-decoration: none; color: inherit; }

.rt-icon-wrapper { padding: 0; margin: 4px; background: transparent; box-shadow: none; border-radius: initial; }
.rt-icon-wrapper > .rt-icon { margin: 0; }
/* #endregion Buttons & Links */

/* #region Checkbox */
.rt-checkbox { cursor: pointer; display: flex; align-items: center; }
.rt-checkbox > label { margin: 0 var(--margin-xxs); cursor: pointer; }
.rt-checkbox > .rt-icon { color: var(--font-color-error); font-size: var(--font-size-xl); }
.rt-checkbox.checked > .rt-icon { color: var(--font-color-success); }
/* #endregion Checkbox */
