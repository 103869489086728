#content-header > .info { display: flex; flex-wrap: wrap; gap: 2em; justify-content: space-around; }

#content-header #spotlight { width: calc(20% - 2em); }
#content-header #spotlight .image-wrapper img { width: 100%; }

#content-header #spotlight h3 { padding: var(--padding-md); }


#content-header #relic-requirements { width: calc(25% - 2em); }
#content-header #important-npcs { width: calc(55% - 2em); }

#content-header ul { list-style: upper-roman; }
#content-header li { margin: var(--margin-xs); }

#content-header .intro-text { order: 5; width: 100%; text-align: center; margin-top: var(--margin-md); padding: var(--padding-md); border-top: 1px solid var(--border-divider-color); }

@media (max-width: 820px /* var(--breakpoint-tablet-max-width) */) {
	#content-header .info > div { flex: 1 0 100%; }
}

@media (max-width: 480px /* var(--breakpoint-mobile-max-width) */) {

}


.yarl__thumbnails .yarl__thumbnails_container { display: none; }
:fullscreen .yarl__thumbnails_container { display: initial; }
