#characters h1 { text-align: center; }

#characters > .your-characters-container { border-bottom: 1px solid var(--border-divider-color); }

#characters .your-characters { display: flex; flex-wrap: wrap; }
#characters .your-characters > p { margin: 0 auto; }

#characters .char-wrapper { position: relative; width: max-content; }

#characters .char-wrapper .achievement-overlay { z-index: -1; opacity: 0; position: absolute; top: var(--margin-xs); left: var(--margin-xs); width: calc(100% - (var(--margin-xs) * 2)); height: calc(100% - (var(--margin-xs) * 2)); background: var(--background-glassmorph); backdrop-filter: blur(var(--glassmorph-backdrop-filter-blur)); border-radius: var(--border-radius-md); display: flex; flex-direction: column; align-items: center; justify-content: center; }
#characters .char-wrapper .achievement-overlay.show { z-index: 1; opacity: 1; animation: showLoadingOverlay var(--animation-speed-slow) ease-in-out; }
#characters .char-wrapper .achievement-overlay.hide { z-index: -1; opacity: 0; animation: hideLoadingOverlay var(--animation-speed-slow) ease-in-out; }
#characters .char-wrapper .achievement-overlay p { margin-bottom: var(--margin-xs); }
#characters .char-wrapper .achievement-overlay svg { animation: rotate 2s infinite linear; }

#characters .char-wrapper .remove-overlay { z-index: -1; opacity: 0; position: absolute; top: var(--margin-xs); left: var(--margin-xs); width: calc(100% - (var(--margin-xs) * 2)); height: calc(100% - (var(--margin-xs) * 2)); background: var(--background-error); border-radius: var(--border-radius-md); display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center; }
#characters .char-wrapper .remove-overlay.show { z-index: 1; opacity: 1; animation: showLoadingOverlay var(--animation-speed-slow) ease-in-out; }
#characters .char-wrapper .remove-overlay.hide { z-index: -1; opacity: 0; animation: hideLoadingOverlay var(--animation-speed-slow) ease-in-out; }

#characters .save-changes { text-align: center; margin: var(--margin-sm); }

#characters .find-character-wrapper { display: flex; }
#characters .find-character { margin-top: var(--margin-md); padding: 0 var(--padding-md); text-align: center; border-right: 1px solid var(--border-divider-color); min-width: fit-content; }
#characters .search-wrapper { flex-grow: 1; text-align: center; }
#characters .search-results { display: flex; flex-wrap: wrap; }
#characters .searching { --loadingGrow-scale: 2; animation: loadingGrow var(--animation-speed-slow) ease-in-out infinite; display: block; margin: var(--margin-sm) auto; }
#characters .no-results { margin: 0 auto; }

#characters .reset-data-wrapper > p { text-align: center; margin: var(--margin-sm); }
#characters .reset-data { display: flex; justify-content: center; gap: 2em; flex-wrap: wrap; }

#characters .anonymous-character { display: flex; justify-content: center; align-items: center; }
#characters .anonymous-character svg { margin: 0 var(--margin-sm); color: var(--font-color-info); text-align: center; }

#characters .import-backup { margin: 0 auto; text-align: center; }

@media (max-width: 820px /* var(--breakpoint-tablet-max-width) */) {
	#characters .find-character-wrapper { flex-direction: column; }
	#characters .find-character { border: none; }
}

@media (max-width: 480px /* var(--breakpoint-mobile-max-width) */) {
}
