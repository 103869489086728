#progress .options { display: flex; justify-content: center; gap: 2em; }

#progress-stats .col-wrapper { display: flex; gap: 1em; justify-content: center; }

#progress-stats .col .table-wrapper { margin-left: 0; }
#progress-stats .col-wrapper:not(.compact) .col .table-wrapper { width: 100%; }

#progress-stats .col-wrapper:not(.compact) .table { width: 100%; }

#progress-stats .compact .table td { padding: calc(var(--padding-xs) * 1.5) var(--padding-sm); }

#progress-stats .table td { text-align: right; }
#progress-stats .table td:first-child { text-align: left; }

#progress-stats .compact .table:not(.overall) td:first-child { text-align: right; }
#progress-stats .compact .table td:nth-child(4) { text-align: center; }


@media (max-width: 820px /* var(--breakpoint-tablet-max-width) */) {
	
}

@media (max-width: 480px /* var(--breakpoint-mobile-max-width) */) {
	
}
