@import-normalize;

* { box-sizing: border-box; }

html { font-size: 16px; user-select: none; background: var(--dark-background-primary); }
body { margin: 0; }

/* TODO: Theme the scrollbar colours */
/* #region Scrollbars */
::-webkit-scrollbar { background-color: whitesmoke; width: 0.5em; }
::-webkit-scrollbar-track, ::-webkit-scrollbar-corner { background-color: whitesmoke; box-shadow: 0 0 6px 1px black inset; }
::-webkit-scrollbar-thumb { border-radius: 6px; background-color: rgba(214, 41, 41, 0.9); background-image: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.4) 50%, transparent); }
::-webkit-scrollbar-thumb:vertical:hover, ::-webkit-scrollbar-thumb:horizontal:hover { background-color: #d62929; }
/* #endregion */

#site-wrapper { display: flex; flex-direction: column; background: var(--background-site); color: var(--font-color-primary); width: 100vw; height: 100vh; overflow: hidden; animation: themeChangeFadeIn var(--animation-speed-slowest) ease-in-out; opacity: 1; }
#site-wrapper.theme-change { animation: themeChangeFadeOut var(--animation-speed-slowest) ease-in-out; opacity: 0; }

#main { display: flex; height: calc(100vh - var(--header-height)); }

ul { list-style: none; margin: 0; padding: 0; }
span { font-size: var(--font-size-md); }
p { margin: 0; padding: 0; user-select: text; }

/* #region Headings */
h1 { font-size: var(--font-size-xxl); }
h2 { font-size: var(--font-size-xl); }
h3 { font-size: var(--font-size-lg); }
h4 { font-size: var(--font-size-md); }
h5 { font-size: var(--font-size-sm); }
/* #endregion */

#breadcrumbs { height: var(--breadcrumbs-height); margin-top: var(--margin-lg); padding-bottom: var(--padding-xl); border-bottom: 1px solid var(--border-divider-color); display: flex; justify-content: center; align-items: center; }
#breadcrumbs .marker { margin: 0 var(--margin-sm); }
#breadcrumbs .breadcrumb { font-size: var(--font-size-xl); }

#page-wrapper { flex-grow: 1; background: var(--background-site); position: relative; overflow: hidden; display: flex; flex-direction: column; }
#content-wrapper { overflow: auto; height: calc(100% - var(--breadcrumbs-height) - var(--margin-lg)); }
#content { margin: var(--margin-lg); }

#loading-overlay { background: var(--background-loading); position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; opacity: 1; z-index: 10; }
#loading-overlay.hide { z-index: -1; opacity: 0; animation: hideLoadingOverlay var(--animation-speed-fast) ease-in-out; }
#loading-overlay.show { z-index: 10; opacity: 1; animation: showLoadingOverlay var(--animation-speed-fast) ease-in-out; }
#loading-overlay > .loading > svg { --loadingGrow-scale: 2; position: absolute; animation: loadingGrow var(--animation-speed-slow) ease-in-out infinite; }
