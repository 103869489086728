:root {
	/* Backgrounds */
	--dark-background-site: rgba(34, 34, 34, 1);
	--dark-background-primary: rgba(34, 34, 34, 1);
	--dark-background-secondary: rgba(68, 68, 68, 1);
	--dark-background-success: rgba(32, 136, 32, 1);
	--dark-background-warn: rgba(255, 181, 83, 1);
	--dark-background-error: rgba(205, 50, 50, 0.9);
	--dark-background-loading: rgba(34, 34, 34, 1);
	--dark-background-modal-overlay: rgba(34, 34, 34, 0.9); /* Background Primary + Some Transparency */
	--dark-background-glassmorph: rgba(68, 68, 68, 0.5);
	--dark-background-field-changed: rgba(68, 195, 245, 1);
	--dark-background-disabled: rgba(200, 200, 200, 1);
	--dark-background-notifications: rgba(0, 79, 153, 1);
	--dark-background-inputs-primary: rgba(230, 230, 230, 1);
	--dark-background-inputs-secondary: rgba(230, 230, 230, 1);
	--dark-background-success-dark: rgba(0, 25, 0, 1);

	/* Fonts */
	--dark-font-color-primary: rgba(255, 255, 255, 1);
	--dark-font-color-secondary: rgba(255, 255, 255, 0.7);
	--dark-font-color-success: rgba(32, 136, 32, 1);
	--dark-font-color-warn: rgba(255, 181, 83, 1);
	--dark-font-color-error: rgba(205, 50, 50, 0.9);
	--dark-font-color-dark: rgba(0, 0, 0, 1);
	--dark-font-color-light: rgba(255, 255, 255, 1);
	--dark-font-color-info: rgba(0, 191, 255, 1);
	--dark-font-color-disabled: rgba(100, 100, 100, 1);
	--dark-font-color-eorzea: rgba(0, 188, 140, 1);

	/* Gradients */
	--dark-gradient-radial-primary: radial-gradient(transparent,rgba(0, 0, 0, 1));
	--dark-gradient-radial-secondary: radial-gradient(rgba(0, 0, 0, 1), transparent);

	/* Borders */
	--dark-border-divider-color: rgba(255, 255, 255, 0.5);

	/* Links */
	--dark-link-color-primary: rgba(0, 191, 255, 1);

	/* Table */
	--dark-table-header-background-primary: rgba(0, 0, 0, 1);
	--dark-table-header-background-secondary: rgba(34, 34, 34, 1);
	--dark-table-body-background-primary: rgba(22, 22, 22, 1);
	--dark-table-body-background-secondary: rgba(34, 34, 34, 1);
	--dark-table-border-divider-color: rgba(255, 255, 255, 0.2);

	/* Other */
	--dark-tip-icon-colour: rgba(255, 255, 0, 1);
	--dark-progress-stats-gradient-colour-1: rgba(150, 0, 0, 0.5);
	--dark-progress-stats-gradient-colour-2: rgba(240, 90, 0, 0.5);
	--dark-progress-stats-gradient-colour-3: rgba(105, 150, 0, 0.5);
	--dark-progress-stats-gradient-colour-4: rgba(0, 150, 0, 0.5);
}
