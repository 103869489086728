.step-details { --details-animation-speed: var(--animation-speed-normal); }


.step-details { border-bottom: 1px solid var(--border-divider-color); transition: padding-bottom var(--details-animation-speed) ease-out; }
.step-details.show { padding-bottom: var(--padding-xl); }

.step-details > h2 { display: inline-block; }
.step-details > h2:hover { transform: scale(1.1); cursor: pointer; }
.step-details > h2 > svg { margin: 0 var(--margin-sm); vertical-align: middle; }
.step-details > h2 > svg:first-child { transform: rotate(-90deg); }
.step-details > h2 > svg:last-child { transform: rotate(90deg); }
.step-details.show > h2 > svg { transform: rotate(0); }

.step-details > div { overflow: hidden; transition: max-height var(--details-animation-speed) ease-out; }

.step-details .job-select { width: fit-content; margin: 0 auto; }
