#item-filters { --flex-gap: 1em; }

#item-filters { display: flex; flex-direction: column; gap: var(--flex-gap); }
#item-filters > div { display: flex; gap: var(--flex-gap); }
#item-filters > .checkboxes { justify-content: center; }
#item-filters > .selects > div { flex: 1; }

#items > p { text-align: center; margin: var(--margin-sm); }

#items .table tbody td.na { color: var(--font-color-disabled); }
#items .table tbody td .relic-detail { display: block; }
