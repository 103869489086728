/* Table Wrapper */ .table-wrapper { white-space: nowrap; overflow: auto; max-width: 100%; width: fit-content; border-radius: var(--border-radius-lg); border: 1px solid var(--border-divider-color); margin: var(--margin-md) auto; }
/* Table */ .table { border-collapse: collapse; white-space: break-spaces; }
/* All table cells */ .table tr > th, .table tr > td { border: 1px solid var(--table-border-divider-color); }

.table thead tr:first-child th { border-top: none; }
.table tr > th:last-child, .table tr > td:last-child { border-right: none; }
.table tbody tr:last-child td, .table tbody tr:nth-last-child(2) td[rowspan="2"] { border-bottom: none; }
.table tr > th:first-child, .table tr > td:first-child { border-left: none; }

/* Header Rows */ .table > thead > tr { background: var(--table-header-background-primary); }
/* Header Cells */ .table > thead > tr > th { padding: var(--padding-sm); }
#overview-table .table > thead > tr > th { padding: var(--padding-xs); }

/* Body Cells */ .table > tbody > tr > td { padding: var(--padding-sm); }
/* Body Cells (Even) */ .table > tbody > tr:nth-child(even) { background: var(--table-body-background-primary); }
/* Body Cells (Odd) */ .table > tbody > tr:nth-child(odd) { background: var(--table-body-background-secondary); }
/* Body Cells (Class Even) */ .table > tbody > tr.even-row { background: var(--table-body-background-primary); }
/* Body Cells (Class Odd) */ .table > tbody > tr.odd-row { background: var(--table-body-background-secondary); }
/* Body Cells with a field */ .table > tbody > tr > td > .field { justify-content: center; }
/* Body Cells Checked */ .table > tbody > tr.complete  { background: var(--background-success-dark); }
/* Override button style */ .table button.no-margin { margin: 0; }
/* All Complete Row p */ .table tr.all-complete p { padding: var(--padding-sm) 0; }
/* All Complete Row button */ .table tr.all-complete p + .input-wrapper > button { margin-top: 0; }

/* #region Table with Sort Options */
.table.sortable > thead > tr > th > div { display: flex; justify-content: flex-end; align-items: center; }
.table.sortable > thead > tr > th > div > span { flex-grow: 1; margin-right: var(--margin-xs); }
.table.sortable > thead > tr > th:not([data-sortfield]) > div > span { margin-right: initial; }
.table.sortable > thead > tr > th[data-sortfield] { cursor: pointer; }
/* #endregion Table with Sort Options */

#relic-checklist { border-bottom: 1px solid var(--border-divider-color); padding-bottom: var(--padding-xxl); }
.relic { text-align: center; }

.light-info { display: flex; justify-content: center; }
.light-info > div { margin: 0 var(--margin-xs); }
.light-info .button-group { width: 100%; }
.light-info .button-group button { width: calc(50% - (2 * var(--margin-sm))); }

@media (max-width: 480px /* var(--breakpoint-mobile-max-width) */) {
	.light-info { flex-direction: column; }
}

/* #region Lightbox */
.yarl__thumbnails_thumbnail.yarl__thumbnails_thumbnail_active { border: 1px solid var(--accent-colour); }
/* #endregion Lightbox */
