.rt-icon { font-size: var(--font-size-lg); width: 32px; margin-left: 4px; margin-right: 2px; vertical-align: middle; }
.rt-icon.sm { width: 24px; }
.rt-icon.success { color: var(--font-color-success); }
.rt-icon.info { color: var(--font-color-info); }
.rt-icon.warn { color: var(--font-color-warn); }
.rt-icon.error { color: var(--font-color-error); }

/* rt-icon-wrapper comes from Icon buttons - Only give these ones interactable styling */
.rt-icon-wrapper .rt-icon { cursor: pointer; }
.rt-icon-wrapper .rt-icon:hover { transform: scale(var(--icon-btn-hover-scale)); }
.rt-icon-wrapper .rt-icon:hover > svg { filter: drop-shadow(var(--drop-shadow) var(--font-color-primary)); }
