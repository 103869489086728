.account-status { padding: 0 var(--padding-md); text-align: center; max-width: 15em; }

.account-status.inline { display: flex; justify-content: space-around; align-items: center; max-width: 100%; padding: var(--padding-md); }
.account-status.inline > button { margin: 0; }

@media (max-width: 820px /* var(--breakpoint-tablet-max-width) */) {
	
}
@media (max-width: 480px /* var(--breakpoint-mobile-max-width) */) {
	
}
